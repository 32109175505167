<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Страницы сайта</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Станица проверки авто</h5>
        </div>
      </div>
      <div class="card-body">
        <p>Настройки страницы</p>
        <a-form-model :model="form">
          <a-form-model-item label="Заголовок(ru)" required>
            <a-input v-model="form.ru.name" />
          </a-form-model-item>
          <a-form-model-item label="Заголовок(ua)" required>
            <a-input v-model="form.ua.name" />
          </a-form-model-item>
          <a-form-model-item label="Позиция" required>
            <a-input v-model="form.position" />
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Описание(ru)" required>
            <quill-editor ref="quillEditor"  v-model="form.ru.description"></quill-editor>
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Описание(ua)" required>
            <quill-editor ref="quillEditor"  v-model="form.ua.description"></quill-editor>
          </a-form-model-item>
        </a-form-model>
        <a-button class="ml-auto mb-5 d-block" type="primary" @click="submit" :disabled="isDisabled">
          Сохранить изменения
        </a-button>
        <check-page-table :list="list" :paginate="pagination" @edit="edit" @overview="overview" />
      </div>
    </div>
    <edit-manager-modal ref="modal" @getList="getList"/>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import CheckPageTable from '@/components/content/pages/check/CheckPageTable'
import EditManagerModal from '@/components/content/pages/check/EditManagerModal'

export default {
  name: 'check',
  data() {
    return {
      list: [],
      form: {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
      search: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },
  computed: {
    isDisabled() {
      const form = this.form
      return !(form.ru.name && form.ua.name && form.ru.description && form.ua.description)
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const page = (await this.$services.get('admin/check')).data.data.data

        this.list = page.managers
        this.form = page.block_pages[0]
      } catch (e) {
        console.log(e)
      }
    },
    edit(id) {
      this.$refs.modal.open(id)
    },
    async submit() {
      try {
        await this.$services.put(`admin/block_pages/${this.form.id}`, this.form)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },
    overview(id) {
      this.$refs.modal.open(id, 'preview')
    },
  },
  components: { CheckPageTable, EditManagerModal, quillEditor },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
