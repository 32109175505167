<template>
  <a-modal v-model="visible" :title="`${form.first_name} ${form.last_name}`" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        {{ isPreview ? 'Ок' : 'Отмена'}}
      </a-button>
      <a-button v-if="!isPreview" key="submit" :disabled="isDisabled" type="primary" @click.prevent.stop="submit">
        Сохранить
      </a-button>
    </template>
    <a-form-model ref="form" :model="form">
      <a-form-model-item label="Фото" required>
        <a-upload
          :disabled="$route.query.is_preview"
          list-type="picture-card"
          :file-list="fileList"
          @change="handleChangeList"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="Имя">
        <a-input v-model="form.first_name" required/>
      </a-form-model-item>
      <a-form-model-item label="Фамилия">
        <a-input v-model="form.last_name" required/>
      </a-form-model-item>
      <a-form-model-item label="Телефон">
        <a-input v-model="form.phone" required/>
      </a-form-model-item>
      <a-form-model-item label="Должность">
        <a-input v-model="form.status" required/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  name: 'EditManagerModal',
  data() {
    return {
      form: {},
      id: '',
      visible: false,
      fileList: [],
      isPreview: false,
    }
  },
  computed: {
    isDisabled() {
      const form = this.form
      return !(form.first_name && form.last_name && form.phone && `${form.status}` && this.fileList.length)
    },
  },
  methods: {
    async open(id, type = '') {
      this.visible = true
      if (type) this.isPreview = true
      this.id = id
      try {
        this.form = (await this.$services.get(`admin/check/managers/${id}`)).data.data.value
        this.fileList.push({
          url: this.form.icon,
          uid: 1,
          name: 'image.png',
          status: 'done',
        })
      } catch (e) {
        console.log(e)
      }
    },
    handleChangeList({ fileList }) {
      this.fileList = fileList
    },
    close() {
      this.visible = false
      this.form = {}
      this.fileList = []
    },
    async submit() {
      if (this.fileList[0].thumbUrl) this.form.image = this.fileList[0].thumbUrl

      try {
        await this.$services.put(`admin/check/managers/${this.id}`, this.form)
        this.$emit('getList')
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
}
</style>
